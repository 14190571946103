import { createStore } from 'vuex'

export default createStore({
  state: {
    tourList: [
      {
        id: 1,
        country_uz: "Armaniston",
        country_ru: "Армения",
        country_en: "Armenia",
        description_uz: "5 kunlik Armaniston kichik guruh safari dunyoda xristianlikni birinchi bo'lib qabul qilgan va din bilan chambarchas bog'liq boy madaniyatga ega bo'lgan mamlakatni ochadi. Siz uning dalillarini Muqaddas Echmiadzin, Xor Virap, Sevanavank, Hayravank, Geghard va boshqa ko'plab go'zal monastirlarda ko'rasiz. Siz ko'pincha \"Armaniston javohiri\" deb ataladigan Sevan ko'li bo'ylab sayohat qilasiz, Yerevandagi buyuk kaskadga ko'tarilasiz, Ararat tog'larini suratga olasiz, Garnining Rim uslubidagi me'morchiligini o'rganasiz va har tomondan hamfikrlar bilan Kavkaz oshxonasining mazali taomlaridan bahramand bo'lasiz. dunyo.",
        description_ru: "Пятидневный тур для небольших групп по Армении откроет вам страну, которая первой в мире приняла христианство и имеет богатую культуру, переплетенную с этой религией. Вы увидите его свидетельства в Святом Эчмиадзине, прекрасных монастырях Хор Вирап, Севанаванк, Айраванк, Гегард и многих других. Вы проедете вдоль озера Севан, которое часто называют «Жемчужиной Армении», подниметесь на великий Каскад в Ереване, сфотографируете горы Арарат, изучите архитектуру Гарни в римском стиле и насладитесь вкусной кавказской кухней с единомышленниками со всего мира. мир.",
        description_en: "The 5-day Armenia small group tour will open up the country that was first to accept Christianity in the world and has a rich culture intertwined with the religion. You will see its evidence at the Holy Echmiadzin, beautiful monasteries of Khor Virap, Sevanavank, Hayravank, Geghard and many more. You will drive along Sevan Lake often called ‘the Jewel of Armenia’, climb the great Cascade in Yerevan, make a photo of the Mountains of Ararat, learn Roman style architecture of Garni and enjoy delicious Caucasus cuisine with like-minded people from all over the world.",
        image: "/img/armenia.png",
        price_uz: "$690 dan boshlab",
        price_ru: "От $690",
        price_en: "From $690",
        stars: 5,
        title_uz: "Armaniston kichik guruh sayohati 2023-2024",
        title_ru: "Тур в малых группах по Армении 2023-2024 гг.",
        title_en: "Armenia Small Group Tour 2023-2024",
        duration_uz: "5 kun 4 kecha",
        duration_ru: "5 дней 4 ночи",
        duration_en: "5 days 4 nights"
      },
      {
        id: 2,
        country_uz: "O'zbekiston",
        country_ru: "Узбекистан",
        country_en: "Uzbekistan",
        description_uz: "Garchi O‘zbekiston qishki ta’til uchun tabiiy tanlovdek ko‘rinmasa-da, qish mamlakatning tinch va osoyishta Ipak yo‘lidagi shaharlarini kashf qilish uchun ideal vaqt. Ushbu Uzbekistan Winter Group Tourda siz Samarqandning afsonaviy savdo markaziga sayohat qilib, karvonlar izidan borasiz; 2000 yillik Buxoro shahridagi masjid va madrasalarni ziyorat qilish; va O‘zbekiston poytaxti Toshkentda eski va yangining uyg‘unligini his eting. Mashhur yodgorliklarni, shu jumladan YuNESKOning ikkita Jahon merosi ob'ektini olomonsiz va agar omadingiz bo'lsa, qor changi bilan suratga oling. Ketish sanasiga qarab, siz Yangi yil bayramini mahalliy uslubda nishonlashingiz mumkin.",
        description_ru: "Хотя Узбекистан может показаться неестественным выбором для зимнего отдыха, зима — идеальное время, чтобы в тишине и спокойствии открыть для себя запоминающиеся города Шелкового пути. В этом зимнем групповом туре по Узбекистану вы пойдете по следам караванов, путешествуя в легендарный торговый центр Самарканд; посетите мечети и медресе в 2000-летнем городе Бухаре; и ощутите сочетание старого и нового в столице Узбекистана Ташкенте. Сфотографируйте знаменитые памятники, в том числе два объекта Всемирного наследия ЮНЕСКО, без толпы и, если повезет, с присыпкой снега. В зависимости от даты отъезда вы также сможете отпраздновать Новый год в местном стиле.",
        description_en: "Although Uzbekistan may not seem like the natural choice for a winter holiday, winter is the ideal time to discover the country’s evocative Silk Road cities in peace and tranquillity. On this Uzbekistan Winter Group Tour, you’ll follow in the footsteps of caravans as you journey to the fabled trading hub of Samarkand; visit mosques and madrassahs in the 2,000-year-old city of Bukhara; and experience the blend of old and new in Uzbekistan’s capital, Tashkent. Photograph the famous monuments, including two UNESCO World Heritage Sites, without the crowds and, if you’re very lucky, with a dusting of snow. Depending on your departure date, you may also be able to celebrate New Year’s Eve in local style.",
        image: "/img/uzbekistan.png",
        price_uz: "$840 dan boshlab",
        price_ru: "От $840",
        price_en: "From $840",
        stars: 5,
        title_uz: "O'zbekiston Qishki Guruh Turi 2023-2024",
        title_ru: "Зимний групповой тур по Узбекистану 2023-2024 гг.",
        title_en: "Uzbekistan Winter Group Tour 2023-2024",
        duration_uz: "8 kun 7 kecha",
        duration_ru: "8 дней 7 ночи",
        duration_en: "8 days 7 nights"
      },
      {
        id: 3,
        country_uz: "Osiyo",
        country_ru: "Азия",
        country_en: "Asia",
        description_uz: "12 kunlik \"Ipak yo'li\" sayohati davomida siz uchta mamlakatni, eng mashhur shaharlarini, diqqatga sazovor joylarini ziyorat qilasiz va bu yerlar mashhur bo'lgan ko'plab go'zal joylarni ko'rasiz. Qirg'izistonda siz Bishkek bo'ylab hayajonli ekskursiyaga ega bo'lasiz, tog'lar bo'ylab haydash, dovonlarga qiyalik. Shimoli-g‘arbiy Xitoy bu mintaqada asosiy savdo nuqtalari bo‘lib xizmat qilgan va hozir ham xizmat qilayotgan Qashg‘ar va Urumchi shaharlarida sizning oldingizga keladi. Ekskursiya yakuniga qadar Qozog‘istonda Olmaota va uning atrofidagi diqqatga sazovor joylarni ko‘rasiz.",
        description_ru: "В течение 12-дневного тура по Шелковому пути вы посетите три страны, самые популярные города, достопримечательности и увидите множество красивых мест, которыми славятся эти земли. В Кыргызстане вас ждет увлекательная экскурсия по Бишкеку, поездка по горам, спускам к перевалам. Северо-западный Китай предстанет перед вами в городах Кашгар и Урумчи, которые служили и остаются основными торговыми узлами в этом регионе. В конце тура по Казахстану вы увидите достопримечательности Алматы и его окрестностей.",
        description_en: "During the 12 day Silk Road tour you will visit three countries, the most popular cities, sights and see many beautiful places that these lands are famous for. In Kyrgyzstan you will have an exciting excursion around Bishkek, driving through the mountains, slope to the passes. North-west China will come in front of you in cities of Kashgar and Urumqi, that had served and still do as main trading junctions in this region. To the end of the tour, in Kazakhstan, you will see sights of Almaty and its surroundings.",
        image: "/img/asia.png",
        price_uz: "$1240 dan boshlab",
        price_ru: "От $1240",
        price_en: "From $1240",
        stars: 5,
        title_uz: "Ipak yo'li sayohati",
        title_ru: "Тур по Шелковому пути",
        title_en: "Silk Road Tour",
        duration_uz: "14 kun 13 kecha",
        duration_ru: "14 дней 13 ночи",
        duration_en: "14 days 13 nights"
      }
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
