import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import("@/layout/MainLayout"),
    redirect: {name: "Home"},
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/HomeView'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import("@/views/AboutView")
      },
      {
        path: '/services',
        name: 'Services',
        component: () => import("@/views/ServicesView")
      },
      {
        path: '/packages',
        name: 'Packages',
        component: () => import("@/views/PackagesView")
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import("@/views/ContactView")
      },
      {
        path: '/packages/:id',
        name: 'PackageDetail',
        component: () => import("@/views/PackageDetailView")
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
